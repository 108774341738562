import { HelmetProvider } from "react-helmet-async";
import { GlobalStyles, lightTheme } from "./styles";
import { ThemeProvider } from "styled-components";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./screens/Layout";
import { Home } from "./screens/Home";
import routes from "./routes";

function App() {
    return (
        <HelmetProvider>
            <ThemeProvider theme={{ ...lightTheme }}>
                <GlobalStyles />
                <BrowserRouter>
                    <Routes>
                        <Route
                            path={`${routes.home}*`}
                            element={
                                <Layout>
                                    <Home />
                                </Layout>
                            }
                        />
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </HelmetProvider>
    );
}

export default App;
