import { createGlobalStyle, styled } from "styled-components";
import { DefaultTheme } from "styled-components/dist/types";
import reset from "styled-reset";

const size = {
    miniMobile: "350px",
    mobile: "600px",
    tablet: "900px",
    laptop: "1400px",
    desktop: "1800px",
};

export const lightTheme: DefaultTheme = {
    blue: "#0f5e9c",
    lightBlue: "#4D96FF",
    black: "#23272a",
    accent: "#0f5e9c",
    background: "#f7f7f7",
    border: "#99aab5",
    borderLight: "#eeeeee",
    lightBeige: "#F5F5DD",
    font: "#23272a",
    fontGray: "#99aab5",
    fontDarkGray: "#777777",
    white: "#ffffff",
    red: "#d9534f",
    green: "#00A35E",
    footerBackground: "#17202A",
    navigationBackground: "#EEEEEE",
    bookingSC: "#f3d9fa",
    bookingNA: "#b2f2bb",
    bookingSchedule: "#ffd8a8",
    bookingUsed: "#DDDDDD",
    chartSC: "#A236E3",
    chartNA: "#029C48",
    miniMobile: `(max-width: ${size.miniMobile})`,
    mobile: `(max-width: ${size.mobile})`,
    tablet: `(max-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`,
    desktop: `(min-width: ${size.desktop})`,
};

export const ButtonSpan = styled.span<{ disabled?: Boolean }>`
    user-select: none;
    cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
    opacity: ${(props) => (props.disabled ? "0.3" : "1")};
`;

export const Input = styled.input`
    color: ${(props) =>
        props.disabled ? props.theme.fontGray : props.theme.black};
    background: ${(props) => props.theme.white};
    outline: none;
`;

export const LogoFontSpan = styled.span`
    font-family: sans-serif;
    user-select: none;
    line-height: 0%;
    font-weight: 700;
`;

export const GlobalStyles = createGlobalStyle`
    ${reset}
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    ::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${(props) => props.theme.border};
        border-radius: 5px;
    }

    ::-webkit-scrollbar-track {
        background-color: ${(props) => props.theme.borderLight};
    }

    ::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }

    body {
        font-family: sans-serif;
        word-break: keep-all;
        background-color: ${(props) => props.theme.background};
    }
`;
