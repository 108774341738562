import { styled } from "styled-components";
import { HEADER_HEIGHT, Header } from "../components/Header";
import { Footer } from "../components/Footer";

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const ImageContainer = styled.div`
    display: flex;
`;

const ImageGradient = styled.div`
    width: 100%;
    height: 100vh;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const TopImage = styled.img`
    width: 100%;
    height: 100vh;
    object-fit: cover;
`;

const TextContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    top: ${HEADER_HEIGHT};
    width: 100%;
    align-items: center;
`;

const TextSubContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px;
    padding-top: 10px;
    width: 600px;
`;

const TextRoot = styled.span`
    color: white;
`;

const Title = styled(TextRoot)`
    font-size: 25px;
    margin-top: 60px;
    line-height: 115%;
`;

const Text = styled(TextRoot)`
    font-size: 20px;
    margin-top: 10px;
    line-height: 115%;
`;

export const Home = () => {
    return (
        <Container>
            <ImageContainer>
                <TopImage src={require("../assets/images/home_top.jpg")} />
                <ImageGradient />
            </ImageContainer>
            <Header />
            <TextContainer>
                <TextSubContainer>
                    <Title>
                        At Coren, we are not just a company; we are architects
                        of the future. Established on September 2023, our
                        journey began with a simple yet powerful vision — to
                        transform industries, disrupt norms, and usher in a new
                        era of innovation.
                    </Title>
                    <Title>Who We Are</Title>
                    <Text>
                        Coren group is a dynamic startup that thrives on pushing
                        boundaries and challenging the status quo. As a team of
                        passionate visionaries, technologists, and creatives, we
                        are dedicated to crafting solutions that resonate with
                        the evolving needs of the modern world.
                    </Text>
                </TextSubContainer>
            </TextContainer>
            <Footer />
        </Container>
    );
};
