import { styled } from "styled-components";
import { useState } from "react";
import { ContactPopup } from "./popups/ContactPopup";

const SHeader = styled.header`
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    a {
        color: ${(props) => props.theme.font};
        text-decoration: none;
    }
    border-top: 0.5px solid white;
    bottom: 0;
    color: white;
`;

const Wrapper = styled.div`
    max-width: 1500px;
    min-width: 320px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 25px;
`;

const Text = styled.span`
    font-size: 13px;
`;

export const Footer = () => {
    const [contactPopup, setContactPopup] = useState(false);

    return (
        <SHeader>
            <Wrapper>
                <Text>Copyright © 2023 Coren Co., Ltd.</Text>
            </Wrapper>
            {contactPopup ? (
                <ContactPopup
                    didClosePopup={() => {
                        setContactPopup(false);
                    }}
                />
            ) : null}
        </SHeader>
    );
};
