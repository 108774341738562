import styled from "styled-components";
import { Popup } from "./Popup";

interface ITextPopup {
    didClosePopup?: () => void;
    style?: React.CSSProperties;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.span`
    margin-bottom: 20px;
    font-weight: 700;
    width: 100%;
    text-align: center;
`;
const Text = styled.span`
    margin-top: 8px;
`;

export function ContactPopup({ didClosePopup, style }: ITextPopup) {
    return (
        <Popup didClosePopup={didClosePopup}>
            <Container>
                <Title>Contact us</Title>
                <Text
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        window.location.href = "mailto:support@corengroup.com";
                    }}
                >
                    Email: support@corengroup.com
                </Text>{" "}
                <Text>Address: 11-14, Simin-daero 327beon-gil </Text>
            </Container>
        </Popup>
    );
}
