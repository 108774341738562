import { Link } from "react-router-dom";
import { styled } from "styled-components";
import routes from "../routes";
import { ButtonSpan, LogoFontSpan } from "../styles";
import { useState } from "react";
import { ContactPopup } from "./popups/ContactPopup";

export const HEADER_HEIGHT = "100px";

const SHeader = styled.header`
    position: absolute;
    background-color: rgba(0, 0, 0, 0);
    height: ${HEADER_HEIGHT};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    a {
        color: ${(props) => props.theme.font};
        text-decoration: none;
    }
    border-bottom: 0.5px solid white;
`;

const Wrapper = styled.div`
    max-width: 1500px;
    min-width: 320px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
`;

const Column = styled.div`
    display: flex;
    align-items: center;
`;

const Logo = styled(LogoFontSpan)`
    font-size: 30px;
    a {
        color: ${(props) => props.theme.white};
    }

    @media ${({ theme }) => theme.mobile} {
        font-size: 20px;
    }
`;

const HeaderButton = styled(ButtonSpan)`
    font-size: 17px;
    font-weight: 700;
    font-size: 700;
    margin-left: 30px;
    color: ${(props) => props.theme.white};
    a {
        color: ${(props) => props.theme.white};
    }

    @media ${({ theme }) => theme.mobile} {
        font-size: 13px;
    }
`;

export const Header = () => {
    const [contactPopup, setContactPopup] = useState(false);

    return (
        <SHeader>
            <Wrapper>
                <Column>
                    <Logo>
                        <Link to={routes.home}>Coren Group</Link>
                    </Logo>
                </Column>
                <Column>
                    <HeaderButton>
                        <Link to={routes.home}>Overview</Link>
                    </HeaderButton>
                    <HeaderButton
                        onClick={() => {
                            setContactPopup(true);
                        }}
                    >
                        Contact
                    </HeaderButton>
                </Column>
            </Wrapper>
            {contactPopup ? (
                <ContactPopup
                    didClosePopup={() => {
                        setContactPopup(false);
                    }}
                />
            ) : null}
        </SHeader>
    );
};
