import styled from "styled-components";

const Content = styled.main`
    width: 100%;
    color: ${(props) => props.theme.font};
`;

const Root = styled.div`
    display: flex;
    flex-direction: column;
`;

const Layout = ({ children }: any) => {
    return (
        <Root>
            <Content>{children}</Content>
        </Root>
    );
};

export default Layout;
